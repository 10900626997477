import { getTheme } from 'src/utils/get-theme';

const { isHosTheme } = getTheme();

export enum FaqCategories {
    Popular = 'popular',
    MovingIn = 'moving-in',
    MovingOut = 'moving-out',
    CancellingBooking = 'cancelling-booking',
    PayingForMyBooking = 'paying-for-my-booking',
    ManageExistingBooking = 'manage-existing-booking',
    IncludedAt = 'included-at',
    HomeRules = 'home-rules',
    IncludedAtMyRoom = 'included-at-my-room',
    AdditionalFaqs = 'additional-faqs',
}

export const FAQ_CATEGORIES_LIST = isHosTheme
    ? Object.values(FaqCategories)
    : Object.values(FaqCategories).filter((category) => category !== FaqCategories.AdditionalFaqs);

export const FAQ_TRANSLATE_MAP: Record<FaqCategories, string> = {
    [FaqCategories.Popular]: 'faq_category_popular_questions',
    [FaqCategories.MovingIn]: 'faq_category_move_in',
    [FaqCategories.MovingOut]: 'faq_category_move_out',
    [FaqCategories.CancellingBooking]: 'faq_category_cancel',
    [FaqCategories.PayingForMyBooking]: 'faq_category_payments',
    [FaqCategories.ManageExistingBooking]: 'faq_category_manage',
    [FaqCategories.IncludedAt]: 'faq_category_whats_included_at_vita_hos',
    [FaqCategories.HomeRules]: 'faq_category_home_rules',
    [FaqCategories.IncludedAtMyRoom]: 'faq_category_whats_included_in_room',
    [FaqCategories.AdditionalFaqs]: 'faq_category_additional_faqs',
};

export const getFaqTranslationKey = (category: FaqCategories): string => {
    if (category === FaqCategories.IncludedAt) {
        return isHosTheme
            ? 'faq_category_whats_included_at_house_of_social'
            : 'faq_category_whats_included_at_vita_hos';
    }
    return FAQ_TRANSLATE_MAP[category];
};

export const FAQ_DATA = [
    // CATEGORY: move in
    {
        // Can I move in early?
        question: 'faq_question_move_in_can_i_move_in_early',
        answer: isHosTheme
            ? 'faq_answer_move_in_can_i_move_in_early_hos'
            : 'faq_answer_move_in_can_i_move_in_early',
        categories: [FaqCategories.MovingIn],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // When is my move in day?
        question: 'faq_question_move_in_when-is-my-move-in-day',
        answer: isHosTheme
            ? 'faq_answer_move_in_when-is-my-move-in-day_hos'
            : 'faq_answer_move_in_when-is-my-move-in-day',
        categories: [FaqCategories.MovingIn],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Are there specific times of the day that I can move in?
        question: 'faq_question_move_in_are-there-specific-times-of-day-i-can-move-in',
        answer: isHosTheme
            ? 'faq_answer_move_in_are-there-specific-times-of-day-i-can-move-in_hos'
            : 'faq_answer_move_in_are-there-specific-times-of-day-i-can-move-in',
        categories: [FaqCategories.MovingIn],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // What is the address of the building?
        question: 'faq_question_move_in_what_is_the_address_of_building',
        answer: isHosTheme
            ? 'faq_answer_move_in_what_is_the_address_of_building_hos'
            : 'faq_answer_move_in_what_is_the_address_of_building',
        categories: [FaqCategories.MovingIn],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Is there parking available outside on move in day?
        question: 'faq_question_move_in_is-there-parking-available-on-move-in-day',
        answer: isHosTheme
            ? 'faq_answer_move_in_is-there-parking-available-on-move-in-day_hos'
            : 'faq_answer_move_in_is-there-parking-available-on-move-in-day',
        categories: [FaqCategories.MovingIn],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Can my parents stay over whilst moving in?
        question: 'faq_question_move_in_can-my-parents-stay-over-whilst-moving-in',
        answer: 'faq_answer_move_in_can-my-parents-stay-over-whilst-moving-in',
        categories: [FaqCategories.MovingIn],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // What do I need to bring with me?
        question: 'faq_question_move_in_what-do-i-need-to-bring-with-me',
        answer: isHosTheme
            ? 'faq_answer_move_in_what-do-i-need-to-bring-with-me_hos'
            : 'faq_answer_move_in_what-do-i-need-to-bring-with-me',
        categories: [FaqCategories.MovingIn],
        showOnVs: true,
        showOnHos: true,
    },

    // CATEGORY: Moving out of Vita Student
    {
        // How do I move out?
        question: 'faq_question_move_out_how-do-i-move-out',
        answer: isHosTheme
            ? 'faq_answer_move_out_how-do-i-move-out_hos'
            : 'faq_answer_move_out_how-do-i-move-out',
        categories: [FaqCategories.MovingOut],
        showOnVs: true,
        showOnHos: true,
    },

    // CATEGORY: Cancelling my booking
    {
        // What is your cancellation policy? (22/23)
        question: 'faq_question_cancel_cancellation-policy',
        answer: isHosTheme
            ? 'faq_answer_cancel_cancellation-policy_hos'
            : 'faq_answer_cancel_cancellation-policy',
        categories: [FaqCategories.CancellingBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // I didn’t get the grades I needed, can I cancel?
        question: 'faq_question_cancel_i-didnt-get-the-grades-i-needed',
        answer: isHosTheme
            ? 'faq_answer_cancel_i-didnt-get-the-grades-i-needed_hos'
            : 'faq_answer_cancel_i-didnt-get-the-grades-i-needed',
        categories: [FaqCategories.CancellingBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // I didn’t get my VISA, can I cancel?
        question: 'faq_question_cancel_i-didnt-get-my-visa',
        answer: isHosTheme
            ? 'faq_answer_cancel_i-didnt-get-my-visa_hos'
            : 'faq_answer_cancel_i-didnt-get-my-visa',
        categories: [FaqCategories.CancellingBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // My place on the university course was not confirmed, can I cancel?
        question: 'faq_question_cancel_place-on-the-university-course-was-not-confirmed',
        answer: isHosTheme
            ? 'faq_answer_cancel_place-on-the-university-course-was-not-confirmed_hos'
            : 'faq_answer_cancel_place-on-the-university-course-was-not-confirmed',
        categories: [FaqCategories.CancellingBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Can I leave early and get the remainder of my license fee back?
        question: 'faq_question_cancel_can-i-leave-early-and-get-license-fee-back',
        answer: isHosTheme
            ? 'faq_answer_cancel_can-i-leave-early-and-get-license-fee-back_hos'
            : 'faq_answer_cancel_can-i-leave-early-and-get-license-fee-back',
        categories: [FaqCategories.CancellingBooking],
        showOnVs: true,
        showOnHos: true,
    },

    // CATEGORY: Paying for my booking
    {
        // How do I make a payment?
        question: 'faq_question_payments_how-do-i-make-a-payment',
        answer: isHosTheme
            ? 'faq_answer_payments_how-do-i-make-a-payment_hos'
            : 'faq_answer_payments_how-do-i-make-a-payment',
        categories: [FaqCategories.PayingForMyBooking, FaqCategories.Popular],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // How can my parents make a payment?
        question: 'faq_question_payments_how-can-my-parents-make-a-payment',
        answer: 'faq_answer_payments_how-can-my-parents-make-a-payment',
        categories: [FaqCategories.PayingForMyBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // How can I download a Receipt for payment made?
        question: 'faq_question_payments_how-can-i-download-a-receipt-for-payment-made',
        answer: isHosTheme
            ? 'faq_answer_payments_how-can-i-download-a-receipt-for-payment-made_hos'
            : 'faq_answer_payments_how-can-i-download-a-receipt-for-payment-made',
        categories: [FaqCategories.PayingForMyBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Can I pay via Bank Transfer?
        question: 'faq_question_payments_can-i-pay-via-bank-transfer',
        answer: isHosTheme
            ? 'faq_answer_payments_can-i-pay-via-bank-transfer_hos'
            : 'faq_answer_payments_can-i-pay-via-bank-transfer',
        categories: [FaqCategories.PayingForMyBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // What currency can I pay in?
        question: 'faq_question_payments_what-currency-can-i-pay-in',
        answer: isHosTheme
            ? 'faq_answer_payments_what-currency-can-i-pay-in_hos'
            : 'faq_answer_payments_what-currency-can-i-pay-in',
        categories: [FaqCategories.PayingForMyBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // I already paid but my payment is not showing under my booking - when will I be able to see my payment?
        question: 'faq_question_payments_when-will-i-be-able-to-see-my-payment',
        answer: isHosTheme
            ? 'faq_answer_payments_when-will-i-be-able-to-see-my-payment_hos'
            : 'faq_answer_payments_when-will-i-be-able-to-see-my-payment',
        categories: [FaqCategories.PayingForMyBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // What happens if I can’t make my payment on time?
        question: 'faq_question_payments_what-happens-if-i-cant-make-payment-on-time',
        answer: isHosTheme
            ? 'faq_answer_payments_what-happens-if-i-cant-make-payment-on-time_hos'
            : 'faq_answer_payments_what-happens-if-i-cant-make-payment-on-time',
        categories: [FaqCategories.PayingForMyBooking, FaqCategories.Popular],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Can I change my instalment plan?
        question: 'faq_question_payments_can-i-change-my-instalment-plan',
        answer: isHosTheme
            ? 'faq_answer_payments_can-i-change-my-instalment-plan_hos'
            : 'faq_answer_payments_can-i-change-my-instalment-plan',
        categories: [FaqCategories.PayingForMyBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Can I delay my payment till I get my grades / visa?
        question: 'faq_question_payments_can-i-delay-my-payment-till-i-get-my-grades-visa',
        answer: isHosTheme
            ? 'faq_answer_payments_can-i-delay-my-payment-till-i-get-my-grades-visa_hos'
            : 'faq_answer_payments_can-i-delay-my-payment-till-i-get-my-grades-visa',
        categories: [FaqCategories.PayingForMyBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // What happens if my loan doesn't get paid on time and I can’t pay my instalment before the due date?
        question: 'faq_question_payments_what-happens-if-my-loan-doesnt-get-paid-on-time',
        answer: 'faq_answer_payments_what-happens-if-my-loan-doesnt-get-paid-on-time',
        categories: [FaqCategories.PayingForMyBooking],
        showOnVs: true,
        showOnHos: true,
    },

    // CATEGORY: Manage or query an existing booking
    {
        // I haven’t received my grades yet
        question: 'faq_question_manage_i-havent-received-my-grades-yet',
        answer: isHosTheme
            ? 'faq_answer_manage_i-havent-received-my-grades-yet_hos'
            : 'faq_answer_manage_i-havent-received-my-grades-yet',
        categories: [FaqCategories.ManageExistingBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // I haven’t got my visa yet
        question: 'faq_question_manage_i-havent-got-my-visa-yet',
        answer: isHosTheme
            ? 'faq_answer_manage_i-havent-got-my-visa-yet_hos'
            : 'faq_answer_manage_i-havent-got-my-visa-yet',
        categories: [FaqCategories.ManageExistingBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // What is my booking reference (VITA REF)?
        question: isHosTheme
            ? 'faq_question_manage_what-is-my-hos-ref'
            : 'faq_question_manage_what-is-my-vita-ref',
        answer: isHosTheme
            ? 'faq_answer_manage_what-is-my-hos-ref'
            : 'faq_answer_manage_what-is-my-vita-ref',
        categories: [FaqCategories.ManageExistingBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Can I stay at Vita Student longer?
        question: isHosTheme
            ? 'faq_question_manage_can-i-stay-at-hos-longer'
            : 'faq_question_manage_can-i-stay-at-vita-student-longer',
        answer: isHosTheme
            ? 'faq_answer_manage_can-i-stay-at-hos-longer'
            : 'faq_answer_manage_can-i-stay-at-vita-student-longer',
        categories: [FaqCategories.ManageExistingBooking, FaqCategories.Popular],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Can someone else access my room to drop things off or collect things?
        question: 'faq_uk_question_manage_can-someone-else-access-my-room-to-drop-things-off',
        answer: isHosTheme
            ? 'faq_answer_manage_can-someone-else-access-my-room-to-drop-things-off_hos'
            : 'faq_answer_manage_can-someone-else-access-my-room-to-drop-things-off',
        categories: [FaqCategories.ManageExistingBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Has my discount been applied?
        question: 'faq_question_manage_has-my-discount-been-applied',
        answer: isHosTheme
            ? 'faq_answer_manage_has-my-discount-been-applied_hos'
            : 'faq_answer_manage_has-my-discount-been-applied',
        categories: [FaqCategories.ManageExistingBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Can I swap to a different room or building?
        question: 'faq_question_manage_can-i-swap-into-a-different-room-or-building',
        answer: 'faq_answer_manage_can-i-swap-into-a-different-room-or-building',
        categories: [FaqCategories.ManageExistingBooking],
        showOnVs: true,
        showOnHos: false,
    },

    {
        // Do you allow couples to stay / visit?
        question: isHosTheme
            ? 'faq_question_manage_do-you-allow-couples-to-live-here'
            : 'faq_question_manage_do-you-allow-couples-to-stay-visit',
        answer: isHosTheme
            ? 'faq_answer_manage_do-you-allow-couples-to-live-here'
            : 'faq_answer_manage_do-you-allow-couples-to-stay-visit',
        categories: [FaqCategories.ManageExistingBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Can I have guests to stay / visit?
        question: 'faq_question_manage_can-i-have-guests-stay',
        answer: isHosTheme
            ? 'faq_answer_manage_can-i-have-guests-stay_hos'
            : 'faq_answer_manage_can-i-have-guests-stay',
        categories: [FaqCategories.ManageExistingBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // What bills do I need to pay?
        question: 'faq_question_manage_what-bills-do-i-need-to-pay',
        answer: isHosTheme
            ? 'faq_answer_manage_what-bills-do-i-need-to-pay_hos'
            : 'faq_answer_manage_what-bills-do-i-need-to-pay',
        categories: [FaqCategories.ManageExistingBooking],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Is contents insurance included in my rent?
        question: 'faq_question_manage_is-contents-insurance-included-in-rent',
        answer: isHosTheme
            ? 'faq_answer_manage_is-contents-insurance-included-in-rent_hos'
            : 'faq_answer_manage_is-contents-insurance-included-in-rent',
        categories: [FaqCategories.ManageExistingBooking],
        showOnVs: true,
        showOnHos: true,
    },

    // CATEGORY: What’s included at Vita Student?
    {
        // What's in my building?
        question: 'faq_question_whats_included_at_vita_whats-in-my-building',
        answer: isHosTheme
            ? 'faq_answer_whats_included_at_hos_whats-in-my-building'
            : 'faq_answer_whats_included_at_vita_whats-in-my-building',
        categories: [FaqCategories.IncludedAt],
        showOnVs: true,
        showOnHos: true,
    },
    {
        // What facilities can I use as part of the early access benefit if I book for next year?
        question:
            'faq_question_whats_included_at_vita_what-facilities-can-i-use-as-part-of-early-access-benefit',
        answer: 'faq_answer_whats_included_at_vita_what-facilities-can-i-use-as-part-of-early-access-benefit',
        categories: [FaqCategories.IncludedAt],
        showOnVs: true,
        showOnHos: false,
    },

    {
        // Is there WiFi in the building?
        question: 'faq_question_whats_included_at_vita_is-there-wifi-in-building',
        answer: isHosTheme
            ? 'faq_answer_whats_included_at_hos_is-there-wifi-in-building'
            : 'faq_answer_whats_included_at_vita_is-there-wifi-in-building',
        categories: [FaqCategories.IncludedAt],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // How fast is the internet connection in the building?
        question: 'faq_question_whats_included_at_vita_how-fast-is-the-internet-connection',
        answer: isHosTheme
            ? 'faq_answer_whats_included_at_hos_how-fast-is-the-internet-connection'
            : 'faq_answer_whats_included_at_vita_how-fast-is-the-internet-connection',
        categories: [FaqCategories.IncludedAt],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Is there a common room?
        question: 'faq_question_whats_included_at_vita_is-there-a-common-room',
        answer: isHosTheme
            ? 'faq_answer_whats_included_at_hos_is-there-a-common-room'
            : 'faq_answer_whats_included_at_vita_is-there-a-common-room',
        categories: [FaqCategories.IncludedAt],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Where can I store my bicycle?
        question: 'faq_question_whats_included_at_vita_where-can-i-store-my-bicycle',
        answer: 'faq_answer_whats_included_at_vita_where-can-i-store-my-bicycle',
        categories: [FaqCategories.IncludedAt],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Can I bring my car?
        question: 'faq_question_whats_included_at_vita_can-i-bring-my-car',
        answer: isHosTheme
            ? 'faq_answer_whats_included_at_hos_can-i-bring-my-car'
            : 'faq_answer_whats_included_at_vita_can-i-bring-my-car',
        categories: [FaqCategories.IncludedAt],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Can family and friends bring their cars when they come to visit?
        question: 'faq_question_whats_included_at_vita_can-family-and-friends-bring-their-cars',
        answer: 'faq_answer_whats_included_at_vita_can-family-and-friends-bring-their-cars',
        categories: [FaqCategories.IncludedAt],
        showOnVs: true,
        showOnHos: false,
    },

    {
        // Do you have a gym?
        question: 'faq_question_whats_included_at_vita_do-you-have-a-gym',
        answer: isHosTheme
            ? 'faq_answer_whats_included_at_hos_do-you-have-a-gym'
            : 'faq_answer_whats_included_at_vita_do-you-have-a-gym',
        categories: [FaqCategories.IncludedAt],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Who should I contact if I have a problem with the internet?
        question:
            'faq_question_whats_included_at_vita_who-should-i-contact-if-i-have-problems-with-internet',
        answer: 'faq_answer_whats_included_at_vita_who-should-i-contact-if-i-have-problems-with-internet',
        categories: [FaqCategories.IncludedAt],
        showOnVs: true,
        showOnHos: false,
    },

    {
        // Where do I wash my clothes?
        question: 'faq_question_whats_included_at_vita_where-do-i-wash-my-clothes',
        answer: isHosTheme
            ? 'faq_answer_whats_included_at_hos_where-do-i-wash-my-clothes'
            : 'faq_answer_whats_included_at_vita_where-do-i-wash-my-clothes',
        categories: [FaqCategories.IncludedAt],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Is detergent provided?
        question: 'faq_question_whats_included_at_vita_is-detergent-provided',
        answer: 'faq_answer_whats_included_at_vita_is-detergent-provided',
        categories: [FaqCategories.IncludedAt],
        showOnVs: true,
        showOnHos: false,
    },

    {
        // What’s Nearby?
        question: 'faq_question_whats_included_at_vita_whats-nearby',
        answer: 'faq_answer_whats_included_at_vita_whats-nearby',
        categories: [FaqCategories.IncludedAt],
        showOnVs: true,
        showOnHos: false,
    },

    {
        // How do I access the printer located in reception?
        question: 'faq_question_whats_included_at_vita_how-do-i-access-the-printer',
        answer: isHosTheme
            ? 'faq_answer_whats_included_at_hos_how-do-i-access-the-printer'
            : 'faq_answer_whats_included_at_vita_how-do-i-access-the-printer',
        categories: [FaqCategories.IncludedAt],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // How much does it cost to print at the printer in reception?
        question: 'faq_question_whats_included_at_vita_how-much-does-it-cost-to-print',
        answer: 'faq_answer_whats_included_at_vita_how-much-does-it-cost-to-print',
        categories: [FaqCategories.IncludedAt],
        showOnVs: true,
        showOnHos: false,
    },

    {
        // What if I have forgotten my username and password for the printer?
        question: 'faq_question_whats_included_at_vita_forgot-username-and-password-for-printer',
        answer: 'faq_answer_whats_included_at_vita_forgot-username-and-password-for-printer',
        categories: [FaqCategories.IncludedAt],
        showOnVs: true,
        showOnHos: true,
    },

    // CATEGORY: What’s included in my room?
    {
        // Is there a TV in my room?
        question: 'faq_question_whats_included_in_room_is-there-a-tv-in-my-room',
        answer: isHosTheme
            ? 'faq_answer_whats_included_in_room_is-there-a-tv-in-my-room_hos'
            : 'faq_answer_whats_included_in_room_is-there-a-tv-in-my-room',
        categories: [FaqCategories.IncludedAtMyRoom],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // What happens if there is a breakage or problem with my room?
        question:
            'faq_question_whats_included_in_room_what-happens-if-there-is-a-breakage-or-problem',
        answer: 'faq_answer_whats_included_in_room_what-happens-if-there-is-a-breakage-or-problem',
        categories: [FaqCategories.IncludedAtMyRoom],
        showOnVs: true,
        showOnHos: false,
    },

    {
        // What should I do if I encounter a maintenance issue?
        question: 'faq_question_whats_included_in_room_what-should-i-do-maintenance-issue',
        answer: 'faq_answer_whats_included_in_room_what-should-i-do-maintenance-issue',
        categories: [FaqCategories.IncludedAtMyRoom],
        showOnVs: false,
        showOnHos: true,
    },

    {
        // Do I have to supply my own food?
        question: 'faq_question_whats_included_in_room_do-i-have-to-supply-my-own-food',
        answer: 'faq_answer_whats_included_in_room_do-i-have-to-supply-my-own-food',
        categories: [FaqCategories.IncludedAtMyRoom],
        showOnVs: true,
        showOnHos: false,
    },

    {
        // Can my friend store their belongings in my room?
        question:
            'faq_question_whats_included_in_room_can-my-friend-store-their-belongings-in-my-room',
        answer: isHosTheme
            ? 'faq_answer_whats_included_in_room_can-my-friend-store-their-belongings-in-my-room_hos'
            : 'faq_answer_whats_included_in_room_can-my-friend-store-their-belongings-in-my-room',
        categories: [FaqCategories.IncludedAtMyRoom],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // How safe is my room?
        question: 'faq_question_whats_included_in_room_how-safe-is-my-room',
        answer: isHosTheme
            ? 'faq_answer_whats_included_in_room_how-safe-is-my-room_hos'
            : 'faq_answer_whats_included_in_room_how-safe-is-my-room',
        categories: [FaqCategories.IncludedAtMyRoom],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Can I smoke in my room?
        question: 'faq_question_whats_included_in_room_can-i-smoke-in-room',
        answer: isHosTheme
            ? 'faq_answer_whats_included_in_room_can-i-smoke-in-room_hos'
            : 'faq_answer_whats_included_in_room_can-i-smoke-in-room',
        categories: [FaqCategories.IncludedAtMyRoom],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // What are the unacceptable electronic items and why do Vita Student have them?
        question: 'faq_question_whats_included_in_room_what-are-the-unacceptable-electronic-items',
        answer: isHosTheme
            ? 'faq_answer_whats_included_in_room_what-are-the-unacceptable-electronic-items_hos'
            : 'faq_answer_whats_included_in_room_what-are-the-unacceptable-electronic-items',
        categories: [FaqCategories.IncludedAtMyRoom],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // What are the unacceptable electronic items and why do Vita Student have them?
        question: 'faq_question_whats_included_in_room_do-i-need-to-bring-my-own-saucepans',
        answer: isHosTheme
            ? 'faq_answer_whats_included_in_room_do-i-need-to-bring-my-own-saucepans_hos'
            : 'faq_answer_whats_included_in_room_do-i-need-to-bring-my-own-saucepans',
        categories: [FaqCategories.IncludedAtMyRoom],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Is bedding included in my room?
        question: 'faq_question_whats_included_in_room_is-bedding-included-in-my-room',
        answer: 'faq_answer_whats_included_in_room_is-bedding-included-in-my-room',
        categories: [FaqCategories.IncludedAtMyRoom],
        showOnVs: true,
        showOnHos: false,
    },

    // CATEGORY: House rules, disclaimers & wellbeing
    {
        // What are the Vita Student house rules?
        question: 'faq_question_home-rules_disclaimers',
        answer: 'faq_answer_home-rules_disclaimers',
        categories: [FaqCategories.HomeRules],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Do you have any wellbeing documents?
        question: 'faq_question_home-rules_documents',
        answer: 'faq_answer_home-rules_documents',
        categories: [FaqCategories.HomeRules],
        showOnVs: true,
        showOnHos: true,
    },

    {
        // Can I view Vita Student Resident Engagement Strategy?
        question: 'faq_question_resident_engagement_strategy',
        answer: 'faq_answer_resident_engagement_strategy',
        categories: [FaqCategories.HomeRules],
        showOnVs: true,
        showOnHos: false,
    },

    // CATEGORY: Additional FAQs
    {
        // Can I use the common spaces for personal gatherings?
        question: 'faq_question_additional_faqs_can-i-use-common-room',
        answer: 'faq_answer_additional_faqs_can-i-use-common-room',
        categories: [FaqCategories.AdditionalFaqs],
        showOnVs: false,
        showOnHos: true,
    },
    {
        // How do I submit a cancellation request?
        question: 'faq_question_additional_faqs_submit-cancellation-request',
        answer: 'faq_answer_additional_faqs_submit-cancellation-request',
        categories: [FaqCategories.AdditionalFaqs],
        showOnVs: false,
        showOnHos: true,
    },
    {
        // What happens if my cancellation request is approved?
        question: 'faq_question_additional_faqs_what-happens-if-cancellation-request-approved',
        answer: 'faq_answer_additional_faqs_what-happens-if-cancellation-request-approved',
        categories: [FaqCategories.AdditionalFaqs],
        showOnVs: false,
        showOnHos: true,
    },
    {
        // Am I legally bound by the Licence Agreement after the cooling-off period?
        question: 'faq_question_additional_faqs_am-i-legally-bound-to-la-after-cooling-off-period',
        answer: 'faq_answer_additional_faqs_am-i-legally-bound-to-la-after-cooling-off-period',
        categories: [FaqCategories.AdditionalFaqs],
        showOnVs: false,
        showOnHos: true,
    },
    {
        // Can I receive a refund for my Advance Licence Fee Payment if I cancel within the cooling-off period?
        question: 'faq_question_additional_faqs_adv-licence-fee-payment-refund',
        answer: 'faq_answer_additional_faqs_adv-licence-fee-payment-refund',
        categories: [FaqCategories.AdditionalFaqs],
        showOnVs: false,
        showOnHos: true,
    },
    {
        // What is the Occupation Period in the Licence Agreement?
        question: 'faq_question_additional_faqs_what-is-the-occupation-period-in-the-la',
        answer: 'faq_answer_additional_faqs_what-is-the-occupation-period-in-the-la',
        categories: [FaqCategories.AdditionalFaqs],
        showOnVs: false,
        showOnHos: true,
    },
    {
        // What happens if I don’t find a replacement student after moving in
        question:
            'faq_question_additional_faqs_what-happens-if-i-dont-find-a-replacement-student-after-moving-in',
        answer: 'faq_answer_additional_faqs_what-happens-if-i-dont-find-a-replacement-student-after-moving-in',
        categories: [FaqCategories.AdditionalFaqs],
        showOnVs: false,
        showOnHos: true,
    },
    {
        // Can I cancel if my visa application is delayed but not denied?
        question:
            'faq_question_additional_faqs_can-i-cancel-if-my-visa-application-is-delayed-but-not-denied',
        answer: 'faq_answer_additional_faqs_can-i-cancel-if-my-visa-application-is-delayed-but-not-denied',
        categories: [FaqCategories.AdditionalFaqs],
        showOnVs: false,
        showOnHos: true,
    },
    {
        // What happens if I lose my key fob?
        question: 'faq_question_additional_faqs_what-happens-if-i-lose-my-key-fob',
        answer: 'faq_answer_additional_faqs_what-happens-if-i-lose-my-key-fob',
        categories: [FaqCategories.AdditionalFaqs],
        showOnVs: false,
        showOnHos: true,
    },
    {
        // Can I cancel after moving in?
        question: 'faq_question_additional_faqs_can-i-cancel-after-moving-in',
        answer: 'faq_answer_additional_faqs_can-i-cancel-after-moving-in',
        categories: [FaqCategories.AdditionalFaqs],
        showOnVs: false,
        showOnHos: true,
    },
    {
        // What tools are available for studying?
        question: 'faq_question_additional_faqs_what-tools-are-available-for-studying',
        answer: 'faq_answer_additional_faqs_what-tools-are-available-for-studying',
        categories: [FaqCategories.AdditionalFaqs],
        showOnVs: false,
        showOnHos: true,
    },
    {
        // Are events planned for residents?
        question: 'faq_question_additional_faqs_are-events-planned-for-residents',
        answer: 'faq_answer_additional_faqs_are-events-planned-for-residents',
        categories: [FaqCategories.AdditionalFaqs],
        showOnVs: false,
        showOnHos: true,
    },
    {
        // Can I leave early and get the remainder of my licence fee back?
        question:
            'faq_question_additional_faqs_can-i-leave-early-and-get-the-remainder-of-my-licence-fee-back',
        answer: 'faq_answer_additional_faqs_can-i-leave-early-and-get-the-remainder-of-my-licence-fee-back',
        categories: [FaqCategories.AdditionalFaqs],
        showOnVs: false,
        showOnHos: true,
    },
    {
        // Clearing Room Cancellation Policy
        question: 'faq_question_additional_faqs_clearing-room-cancellation-policy',
        answer: 'faq_answer_additional_faqs_clearing-room-cancellation-policy',
        categories: [FaqCategories.AdditionalFaqs],
        showOnVs: false,
        showOnHos: true,
    },
];
