import { FC } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LinkIcon } from 'src/assets/svg/link.svg';
import { BRANDFOLDER_BASE_URL } from 'src/api/concierge';
import { getTheme } from 'src/utils/get-theme';

export const FaqRulesDisclaimers: FC = () => {
    const { t } = useTranslation();
    const { isHosTheme } = getTheme();

    return (
        <Stack gap="16px" marginTop="16px">
            <Link
                target="_blank"
                download
                href={`${BRANDFOLDER_BASE_URL}/2EZAWJN8/at/qgbbh8xrrkqkb44z36pjqp4k/VS23-24-082_House_Rules_ENG.pdf`}
                display="flex"
                alignItems="center"
                gap="8px"
            >
                <Typography variant="subtitle2">
                    {t('faq_answer_home-rules_disclaimers_house-rules-uk')}
                </Typography>

                <LinkIcon />
            </Link>

            {!isHosTheme && (
                <Link
                    target="_blank"
                    download
                    href={`${BRANDFOLDER_BASE_URL}/2EZAWJN8/at/x4v4p987b56q8x2r6h6cwn5x/VS23-24-082_House_Rules_ENG_BCN_1.pdf`}
                    display="flex"
                    alignItems="center"
                    gap="8px"
                >
                    <Typography variant="subtitle2">
                        {t('faq_answer_home-rules_disclaimers_house-rules-sp-en')}
                    </Typography>

                    <LinkIcon />
                </Link>
            )}

            {!isHosTheme && (
                <Link
                    target="_blank"
                    download
                    href={`${BRANDFOLDER_BASE_URL}/2EZAWJN8/at/j83g46rctk68s9mvc9n58j/VS23-24-082_House_Rules_ESP_1.pdf`}
                    display="flex"
                    alignItems="center"
                    gap="8px"
                >
                    <Typography variant="subtitle2">
                        {t('faq_answer_home-rules_disclaimers_house-rules-sp-es')}
                    </Typography>

                    <LinkIcon />
                </Link>
            )}
        </Stack>
    );
};
