import { FC } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Button, Stack, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';
import { getTheme } from 'src/utils/get-theme';

import {
    FaqCategories,
    FAQ_CATEGORIES_LIST,
    FAQ_TRANSLATE_MAP,
    getFaqTranslationKey,
} from '../../../constants/faq-data';
import { config } from '../../../constants/config';

const getBackground = (isActive: boolean, isHosTheme: boolean, theme: Theme): string => {
    if (isActive) {
        return isHosTheme ? theme.palette.primary.main : theme.palette.secondary.main;
    }
    return theme.palette.background.default;
};

const getColor = (isActive: boolean, isHosTheme: boolean, theme: Theme) => {
    if (isHosTheme && isActive) {
        return theme.palette.secondary.light;
    }
    return theme.palette.text.primary;
};

export const QuestionsCategories: FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { t } = useTranslation();
    const theme = useTheme();
    const { isHosTheme } = getTheme();

    const activeCategory = searchParams.get(config.category_query_key) || config.default_category;

    const handleCategoryChange = (category: FaqCategories) => () => {
        if (activeCategory !== category) {
            const search = {
                [config.category_query_key]: category,
            };

            navigate({ search: `?${createSearchParams(search).toString()}` }, { replace: true });
        }

        GA4.ctaEvent({
            name: 'FAQ Category Change',
            label: t(FAQ_TRANSLATE_MAP[category], { lng: LanguagesCode.English }),
            location: 'FAQ Categories',
        });
    };

    return (
        <Stack
            direction="row"
            gap={{ xs: '8px', lg: '12px' }}
            flexWrap="wrap"
            justifyContent={{ xs: 'flex-start', md: 'center' }}
            marginBottom="60px"
        >
            {FAQ_CATEGORIES_LIST.map((category) => {
                const isActive = activeCategory === category;
                const background = getBackground(isActive, isHosTheme, theme);
                const color = getColor(isActive, isHosTheme, theme);

                return (
                    <Button
                        key={category}
                        onClick={handleCategoryChange(category)}
                        variant="contained"
                        disableElevation
                        css={{
                            background,
                            color,
                            borderRadius: '100px',
                            padding: '16px',

                            ':hover': {
                                background,

                                '[aria-label="category"]': {
                                    fontWeight: 500,
                                },
                            },
                        }}
                    >
                        <Typography
                            variant={isActive ? 'subtitle2' : 'body2'}
                            aria-label="category"
                        >
                            {t(getFaqTranslationKey(category))}
                        </Typography>
                    </Button>
                );
            })}
        </Stack>
    );
};
