import { FC } from 'react';
import { Stack } from '@mui/material';

import { routes } from 'src/constants/routes';
import { getTheme } from 'src/utils/get-theme';

import { SUBJECT_TRANSLATION_KEY } from '../../constants/translation-keys';
import { SUBJECTS, Subject } from '../../constants/form-options';

import { SubjectItem } from './parts/subject-item';
import { SubjectIcon } from './parts/subject-icon';

const { isHosTheme } = getTheme();

const subjects = !isHosTheme ? SUBJECTS : SUBJECTS.filter((subject) => subject !== Subject.Events);

export const SubjectList: FC = () => (
    <Stack padding={{ xs: '26px 0', md: '40px 0' }} width="100%" maxWidth="700px">
        {subjects.map((subject) => (
            <SubjectItem
                key={subject}
                icon={<SubjectIcon subject={subject} />}
                titleKey={SUBJECT_TRANSLATION_KEY[subject]}
                link={routes.feedback.subject.replace(':subject', subject)}
                css={({ palette }) => ({
                    paddingLeft: 0,
                    paddingRight: 0,
                    '&:not(:last-of-type)': {
                        borderBottom: `1px solid ${palette.divider}`,
                    },
                })}
            />
        ))}
    </Stack>
);
