import { FC, Fragment, useEffect, useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';
import { useFeatureFlags } from 'src/features/user';
import { getTheme } from 'src/utils/get-theme';

import { config } from '../../../constants/config';
import { FaqCategories, FAQ_DATA } from '../../../constants/faq-data';
import { searchElements as transComponents } from '../../../constants/search-elements';
import { QuestionAnswer } from '../../question-answer';
import { FaqVideoLinks } from '../../faq-video-links';
import { FaqRulesDisclaimers } from '../../faq-rules-disclaimers';
import { FaqRulesDocuments } from '../../faq-rules-documents';
import { FaqRulesEngagementStrategy } from '../../faq-rules-engagement-strategy';

const BUILDING_VIDEO_QUESTION = 'faq_question_whats_included_at_vita_whats-in-my-building';

export const QuestionsMap: FC = () => {
    const {
        t,
        i18n: { language },
    } = useTranslation();

    const flags = useFeatureFlags();
    const { isHosTheme } = getTheme();

    const [searchParams] = useSearchParams();
    const activeCategory =
        (searchParams.get(config.category_query_key) as FaqCategories) || config.default_category;

    const containerRef = useRef<HTMLDivElement | null>(null);
    const isMounted = useRef(false);

    useEffect(() => {
        const element = containerRef.current;

        if (isMounted.current && element) {
            const { y } = element.getBoundingClientRect();
            window.scrollTo(0, y);
        } else {
            isMounted.current = true;
        }
    }, [activeCategory]);

    const handleAccordionClick = (isOpened: boolean, question: string) =>
        GA4.accordionEvent({
            name: 'FAQ Question Accordion Toggled',
            label: question,
            location: 'FAQ Questions',
            open: isOpened,
        });

    const qaList = useMemo(
        () =>
            FAQ_DATA.reduce((accum, qaData) => {
                if (!qaData.categories.includes(activeCategory)) return accum;

                if (isHosTheme ? !qaData.showOnHos : !qaData.showOnVs) return accum;

                const handleClick = (isOpened: boolean) =>
                    handleAccordionClick(
                        isOpened,
                        t(qaData.question, { lng: LanguagesCode.English })
                    );

                if (!flags.building_videos && qaData.question === BUILDING_VIDEO_QUESTION)
                    return accum;

                accum.push(
                    <QuestionAnswer
                        key={qaData.question}
                        question={<Trans i18nKey={qaData.question} components={transComponents} />}
                        answer={
                            <Fragment>
                                <Trans i18nKey={qaData.answer} components={transComponents} />

                                {qaData.question === BUILDING_VIDEO_QUESTION && !isHosTheme && (
                                    <FaqVideoLinks />
                                )}

                                {qaData.question === 'faq_question_home-rules_disclaimers' && (
                                    <FaqRulesDisclaimers />
                                )}

                                {qaData.question === 'faq_question_home-rules_documents' && (
                                    <FaqRulesDocuments />
                                )}

                                {qaData.question ===
                                    'faq_question_resident_engagement_strategy' && (
                                    <FaqRulesEngagementStrategy />
                                )}
                            </Fragment>
                        }
                        onClick={handleClick}
                    />
                );

                return accum;
            }, [] as JSX.Element[]),
        [activeCategory, language]
    );

    return (
        <div ref={containerRef} css={{ marginBottom: '64px' }}>
            {qaList}
        </div>
    );
};
