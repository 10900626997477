export enum FeedbackFormFields {
    Subject = 'subject',
    StaffCategory = 'staff_category',
    EventId = 'event_id',
    Rating = 'rating',
    Comment = 'comment',
    Attachments = 'attachments',
}

export const feedbackFormDefaultValues = {
    [FeedbackFormFields.Rating]: null,
    [FeedbackFormFields.Comment]: '',
    [FeedbackFormFields.Attachments]: [],
};

export enum Subject {
    Events = 'events',
    Breakfast = 'breakfast',
    HouseKeeping = 'house-keeping',
    Maintenance = 'maintenance',
    Staff = 'staff',
    HealthAndSafety = 'health-and-safety',
    Wifi = 'wifi',
    EverythingElse = 'everything-else',
}

export const SUBJECTS = Object.values(Subject);

export enum SubjectValue {
    Events = 'Events',
    Breakfast = 'Breakfast',
    HouseKeeping = 'Housekeeping',
    Maintenance = 'Maintenance',
    Staff = 'Staff',
    HealthAndSafety = 'Health & Safety',
    Wifi = 'Wi-Fi',
    EverythingElse = 'Everything Else',
}

export const SUBJECT_VALUES: Record<Subject, SubjectValue> = {
    [Subject.Events]: SubjectValue.Events,
    [Subject.Breakfast]: SubjectValue.Breakfast,
    [Subject.HouseKeeping]: SubjectValue.HouseKeeping,
    [Subject.Maintenance]: SubjectValue.Maintenance,
    [Subject.Staff]: SubjectValue.Staff,
    [Subject.HealthAndSafety]: SubjectValue.HealthAndSafety,
    [Subject.Wifi]: SubjectValue.Wifi,
    [Subject.EverythingElse]: SubjectValue.EverythingElse,
};

export enum StaffCategory {
    DayTeam = 'day-team',
    NightTeam = 'night-team',
    Housekeepers = 'housekeepers',
    Management = 'management',
    Maintenance = 'maintenance',
}

export const STAFF_CATEGORIES = Object.values(StaffCategory);

export enum StaffCategoryValue {
    DayTeam = 'Day team',
    NightTeam = 'Night team',
    Housekeepers = 'Housekeepers',
    Management = 'Management',
    Maintenance = 'Maintenance',
}

export const STAFF_CATEGORY_VALUES: Record<StaffCategory, StaffCategoryValue> = {
    [StaffCategory.DayTeam]: StaffCategoryValue.DayTeam,
    [StaffCategory.NightTeam]: StaffCategoryValue.NightTeam,
    [StaffCategory.Housekeepers]: StaffCategoryValue.Housekeepers,
    [StaffCategory.Management]: StaffCategoryValue.Management,
    [StaffCategory.Maintenance]: StaffCategoryValue.Maintenance,
};

export enum RatingOption {
    'One' = '1',
    'Two' = '2',
    'Three' = '3',
    'Four' = '4',
    'Five' = '5',
}

export const RATING_OPTIONS = Object.values(RatingOption);

export const RATING_OPTION_LABELS: Record<RatingOption, string> = {
    [RatingOption.One]: '😣',
    [RatingOption.Two]: '🙁',
    [RatingOption.Three]: '😐',
    [RatingOption.Four]: '🙂',
    [RatingOption.Five]: '😀',
};
