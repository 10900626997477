import { FC } from 'react';

import { ReactComponent as PartyIcon } from 'src/assets/svg/party.svg';
import { ReactComponent as SoupIcon } from 'src/assets/svg/soup.svg';
import { ReactComponent as BroomIcon } from 'src/assets/svg/broom.svg';
import { ReactComponent as ConstructOutlineIcon } from 'src/assets/svg/construct-outline.svg';
import { ReactComponent as StaffIcon } from 'src/assets/svg/users.svg';
import { ReactComponent as HealthIcon } from 'src/assets/svg/health.svg';
import { ReactComponent as TilesIcon } from 'src/assets/svg/tiles.svg';
import { ReactComponent as WifiIcon } from 'src/assets/svg/wifi.svg';

import { Subject } from '../../../constants/form-options';

export const SubjectIcon: FC<{ subject: Subject }> = ({ subject }) => {
    switch (subject) {
        case Subject.Events:
            return <PartyIcon />;
        case Subject.Breakfast:
            return <SoupIcon />;
        case Subject.HouseKeeping:
            return <BroomIcon />;
        case Subject.Maintenance:
            return <ConstructOutlineIcon />;
        case Subject.Staff:
            return <StaffIcon />;
        case Subject.HealthAndSafety:
            return <HealthIcon />;
        case Subject.Wifi:
            return <WifiIcon />;
        case Subject.EverythingElse:
        default:
            return <TilesIcon />;
    }
};
